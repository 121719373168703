export function common() {
  const header = document.getElementById("js-header");

  const headerScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1 && window.innerWidth > 768) {
        header.classList.add("is-scroll");
      } else {
        header.classList.remove("is-scroll");
      }
    }, { passive: true }); // パッシブイベントリスナーに設定
  };

  headerScroll();
}

// jQueryがロードされているか確認し、$を使用可能にする
if (typeof jQuery == 'undefined') {
  console.error("jQueryがロードされていません。");
} else {
  // ProgressBarの設定とアニメーションの実行
  document.addEventListener("DOMContentLoaded", () => {
    var bar = new ProgressBar.Line('#splash_text', { // id名を指定
      easing: 'easeInOut', // アニメーション効果
      duration: 1000, // 時間指定（1000＝1秒）
      strokeWidth: 0.2, // 進捗ゲージの太さ
      color: '#555', // 進捗ゲージのカラー
      trailWidth: 0.2, // ゲージベースの線の太さ
      trailColor: '#bbb', // ゲージベースの線のカラー
      text: { // テキストの形状を直接指定       
        style: { // 天地中央に配置
          position: 'absolute',
          left: '50%',
          top: '50%',
          padding: '0',
          margin: '-30px 0 0 0', // バーより上に配置
          transform: 'translate(-50%,-50%)',
          'font-size': '1rem',
          color: '#fff',
        },
        autoStyleContainer: false // 自動付与のスタイルを切る
      },
      step: function (state, bar) {
        bar.setText(Math.round(bar.value() * 100) + ' %'); // テキストの数値
      }
    });

    // jQueryの$を無名関数内で使用する
    (function($) {
      // アニメーションスタート
      bar.animate(1.0, function () { // バーを描画する割合を指定します 1.0 なら100%まで描画します
        $('#splash_text').fadeOut(500, function() { // フェイドアウトでローディングテキストを削除
          $('.loader_cover-up').addClass('coveranime'); // カバーが上に上がるクラス追加
          $('.loader_cover-down').addClass('coveranime'); // カバーが下に下がるクラス追加
          $('#splash').fadeOut(500); // #splashエリアをフェードアウト
        });
      });
    })(jQuery);
  });
}
