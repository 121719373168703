import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export function slide() {
  if (document.querySelector(".splide01")) {
    new Splide(".splide01", {
      type: "fade",  // フェードトランジションに設定
      perPage: 1,
      perMove: 1,
      autoplay: true,
      interval: 5000,
      pauseOnHover: false,
      focus: 0,
      rewind: true  // 巻き戻しを有効にする
    }).mount();
  }
  if (document.querySelector(".splide02")) {
    new Splide(".splide02", {
      perPage: 3,
      type: "loop",
      focus: 0,
      autoplay: true,
      interval: 3000,
      pauseOnHover: false,
      gap:20,
      breakpoints: {
        768: {
          perPage: 2,
          gap: 20,
        },
        480: {
          perPage: 1,
          gap: 20,
        },
      },
    }).mount();
  }
}
